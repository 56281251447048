<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="clientModal"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title text" id="clientModalLabel">
            <strong style="color: #00364f">Add Client</strong>
          </h4>
          <button
          @click="formNullClientModel()"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="edit == 'false' ? addNewClient() : updateClient()">
            <small v-if="veryfied == 'invalid'" class="text-danger"
              >X <i>Please Enter Valid Pan</i></small
            >
            <small v-if="veryfied == 'false'"></small>
            <div class="row">
              <div class="col-xl-7 col-lg-7 col-md-7 col-12">
                <div class="row">
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Enter Personal Pan <span style="color:#f21000">*</span></label>
                    <input
                     required
                      v-if="panDisabled == 'false'"
                      type="tel"
                      maxlength="10"
                      @change="findRecordeByPanId"
                      v-model="panNo"
                      class="form-control"
                      placeholder="Pan"
                      style="text-transform: uppercase; height: 30px"
                    />

                    <input
                      v-if="panDisabled == 'true'"
                      type="tel"
                      maxlength="10"
                      v-model="form.pan"
                      class="form-control"
                      placeholder=" Pan"
                      style="text-transform: uppercase; height: 30px"
                      disabled
                    />
                    <span v-if="form.errors"
                      ><span v-if="form.errors.error"
                        ><span v-if="form.errors.error.pan"
                          ><span v-if="form.errors.error.pan"
                            ><small style="color: #f21000">{{
                              form.errors.error.pan[0]
                            }}</small></span
                          ></span
                        ></span
                      ></span
                    >
                  </div>
                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Email <span style="color:#f21000">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.email"
                      placeholder="Email Or UserId"
                      style="height: 30px"
                      required
                    />

                    <small style="color: red" v-if="form.errors">
                  <span v-if="form.errors.error"
                    ><span v-if="form.errors.error.email">{{
                      form.errors.error.email[0]
                    }}</span></span
                  ></small
                >


                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>First Name <span style="color:#f21000">*</span></label>
                    <input
                      type="text"
                      required
                      class="form-control"
                      v-model="form.fname"
                      placeholder="First name"
                      style=" height: 30px"
                     
                    />
                    
    
                    <small style="color: red" v-if="form.errors">
                  <span v-if="form.errors.error"
                    ><span v-if="form.errors.error.fname">{{
                      form.errors.error.fname[0]
                    }}</span></span
                  ></small
                >

                    
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Last Name</label>
                    <input
                      type="text"
                      v-model="form.lname"
                      class="form-control"
                      placeholder="Last name"
                      aria-label="Last name"
                      style="height: 30px"
                    />

                    <small style="color: red" v-if="form.errors">
                  <span v-if="form.errors.error"
                    ><span v-if="form.errors.error.lname">{{
                      form.errors.error.lname[0]
                    }}</span></span
                  ></small
                >
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Date Of Birth <span style="color:#f21000">*</span></label>
                    <input
                    required
                      type="date"
                      class="form-control"
                      v-model="form.dob"
                      style=" height: 30px"
                      placeholder="Date Of bith"
                    />
                    <small style="color: red" v-if="form.errors">
                  <span v-if="form.errors.error"
                    ><span v-if="form.errors.error.dob">{{
                      form.errors.error.dob[0]
                    }}</span></span
                  ></small
                >
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Gender <span style="color:#f21000">*</span></label>
                    <select
                    required
                      id="inputState"
                      v-model="form.gender"
                      class="form-select"
                      style="
                       
                        height: 30px;
                        padding: 5px 5px 5px 5px;
                      "
                    >
                      <option selected disabled>Select...</option>
                      <option  value="male">Male</option>
                      <option value="female">FeMale</option>
                      <option value="other">Other</option>
                    </select>
                    <small style="color: red" v-if="form.errors">
                  <span v-if="form.errors.error"
                    ><span v-if="form.errors.error.gender">{{
                      form.errors.error.gender[0]
                    }}</span></span
                  ></small
                >
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>phone <span style="color:#f21000">*</span></label>
                    <input
                    type="tel"
                      maxlength="10"
                      minlength="10"
                      @keydown="accountNoVall "
                      required
                      v-model="form.phone"
                      class="form-control"
                      placeholder="Phone"
                      style="height: 30px"
                    
                    />
                    <small style="color: red" v-if="form.errors">
                  <span v-if="form.errors.error"
                    ><span v-if="form.errors.error.phone">{{
                      form.errors.error.phone[0]
                    }}</span></span
                  ></small
                >
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Alternate Number</label>
                    <input
                      type="tel"
                      maxlength="10"
                      minlength="10"
                      @keydown="accountNoVall "
                      class="form-control"
                      v-model="form.alternativePhone"
                      placeholder="Alternate Number"
                      style="height: 30px"
                     
                    />
                    <small style="color: red" v-if="form.errors">
                  <span v-if="form.errors.error"
                    ><span v-if="form.errors.error.alternativePhone">{{
                      form.errors.error.alternativePhone[0]
                    }}</span></span
                  ></small
                >
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-5 col-12">
                <div class="row">
                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Address line 1 <span style="color:#f21000">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.address"
                      required
                      placeholder="Address Line-1"
                      style=" height: 30px"
                    />
                    <small style="color: red" v-if="form.errors">
                  <span v-if="form.errors.error"
                    ><span v-if="form.errors.error.address">{{
                      form.errors.error.address[0]
                    }}</span></span
                  ></small
                >
                  </div>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Address line 2</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="form.address2"
                      placeholder="Address Line-2"
                      style=" height: 30px"
                    />
                  </div>

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>State <span style="color:#f21000">*</span></label>
                    <select
                    required
                      id="inputState"
                      v-model="form.state_id"
                      class="form-select"
                      style="
                   
                        height: 30px;
                        padding: 5px 5px 5px 5px;
                      "
                    >
                      <option
                        v-for="(state, index) in states"
                        :key="index"
                        :value="state.id"
                      >
                        {{ state.name }}
                      </option>
                    </select>

                    <small style="color: red" v-if="form.errors">
                  <span v-if="form.errors.error"
                    ><span v-if="form.errors.error.state_id">{{
                      form.errors.error.state_id[0]
                    }}</span></span
                  ></small
                >
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>City  <span style="color:#f21000">*</span></label>
                    <input
                    required
                      type="text"
                      v-model="form.city"
                      class="form-control"
                      id="inputCity"
                      placeholder="City"
                      style=" height: 30px"
                    />

                    <small style="color: red" v-if="form.errors">
                  <span v-if="form.errors.error"
                    ><span v-if="form.errors.error.city">{{
                      form.errors.error.city[0]
                    }}</span></span
                  ></small
                >
                  </div>

                  <div
                    class="col-xl-6 col-lg-6 col-md-6 col-12"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <label>Pin  <span style="color:#f21000">*</span></label>
                    <input
                    required
                      style="
                        height: 30px;
                        padding: 5px 5px 5px 5px;
                      "
                      type="tel"
                      maxlength="6"
                      minlength="6"
                      v-model="form.pin"
                      @keydown="accountNoVall "
                      class="form-control"
                   
                      placeholder="Pin"
                    />
                    <span style="color: #f21000" id="pinCode"></span>
                    <small style="color: red" v-if="form.errors">
                  <span v-if="form.errors.error"
                    ><span v-if="form.errors.error.pin">{{
                      form.errors.error.pin[0]
                    }}</span></span
                  ></small
                >
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                @click="formNullClientModel()"
                type="button"
                class="btn btn-sm text-white"
                style="background-color: #f21000"
                data-bs-dismiss="modal"
              >
                <strong>Close</strong>
              </button>
              <button
                type="submit"
                class="btn btn-sm text-white"
                style="background-color: #00364f"
              >
                <strong> Confirm </strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end of the model -->
  <div v-if="loading == true">
          <Spinner />
        </div>

</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import Spinner from "../../../components/super-admin/comman/Spinner.vue";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "ClientsmodalComponents",
  components: {
    Spinner,
  },
  data() {
    return {
      phoneClient: "false",
      phoneAltClient: "false",
      pinCode: "false",
      firstNameClient: "false",
      doBDate: "false",

      panNo: "",
      loading: false,
      client_id: null,
      states: [],
      clients: [],
      edit: "false",
      form: {
        photo: "",
        pan: "",
        fname: "",
        lanme: "",
        phone: "",
        email: "",
        dob: null,
        gender: "male",
        address: null,
        address2: null,
        alternativePhone: null,
        city: null,
        fatherName: "",
        pin: null,
        state_id: 1,
        code: "",
        errors: {},
      },
      panDisabled: "false",
      record: {},
      veryfied: "false",
      exist: "false",
    };
  },
  methods: {
    formNullClientModel(){
this.panNo = ""
      this.form = {}
this.panDisabled  = 'false'
this.veryfied  = 'false'

this.exist  = 'false'
this.edit  = 'false'
      this.form.errors = {}

      this.doBDate = "false";
          $("#doBDate").html("<small></small>");
          this.clientKycAddar = "false";
          $("#clientKycAddar").html("<small></small>");

          this.firstNameClient = "false";
          $("#firstNameClient").html("<small></small>");

          this.phoneClient = "false";
          $("#phoneClient").html("<small></small>");

          
          this.phoneAltClient = "false";
          $("#phoneAltClient").html("<small></small>");


          this.profesionalAddress = "false";
          $("#profesionalAddress").html("<small></small>");


          this.profesionalCity = "false";
          $("#profesionalCity").html("<small></small>");


          this.pinCode = "false";
          $("#pinCode").html("<small></small>");

          

    },
    dateCheker(event, type) {
      var currentDateWithFormat = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
      // console.log(currentDateWithFormat);

      var data = event.target.value;

      if (type == "doBDate") {
        if (currentDateWithFormat < data) {
          this.doBDate = "true";
          console.log(type);
          $("#doBDate").html("<small>It should be less than current date</small>");
        }
        if (currentDateWithFormat >= data) {
          this.doBDate = "false";
          $("#doBDate").html("<small></small>");
        }
      }
      // }
    },
    lenthheker(event, type) {
      var data = event.target.value;
      if (type == "clientKycAddar") {
        if (data.length != 12) {
          this.clientKycAddar = "true";

          $("#clientKycAddar").html("<small>Aadhar required minimum 12 letters</small>");
        }
        if (data.length == 12) {
          this.clientKycAddar = "false";
          $("#clientKycAddar").html("<small></small>");
        }
      }
      // done
      if (type == "firstNameClient") {
        if (data.length < 3) {
          console.log(data.length);
          this.firstNameClient = "true";
          $("#firstNameClient").html(
            "<small>Profesional Name required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.firstNameClient = "false";
          $("#firstNameClient").html("<small></small>");
        }
      }

      if (type == "businessName") {
        if (data.length < 3) {
          console.log(data.length);
          this.businessName = "true";
          $("#businessName").html(
            "<small>Profesional Name required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.businessName = "false";
          $("#businessName").html("<small></small>");
        }
      }
      // done
      if (type == "phoneClient") {
        if (data.length != 10) {
          console.log(data.length);
          this.phoneClient = "true";
          $("#phoneClient").html("<small>Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          console.log(data.length);
          this.phoneClient = "false";
          $("#phoneClient").html("<small></small>");
        }
      }
      // done
      if (type == "phoneAltClient") {
        if (data.length != 10) {
          console.log(data.length);
          this.phoneAltClient = "true";
          $("#phoneAltClient").html("<small>Alt Phone Number 10 Digit Required</small>");
        }
        if (data.length == 10) {
          console.log(data.length);
          this.phoneAltClient = "false";
          $("#phoneAltClient").html("<small></small>");
        }
      }

      if (type == "profesionalAddress") {
        if (data.length < 3) {
          console.log(data.length);
          this.profesionalAddress = "true";
          $("#profesionalAddress").html(
            "<small>Address required minimum 3 letters</small>"
          );
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.profesionalAddress = "false";
          $("#profesionalAddress").html("<small></small>");
        }
      }
      if (type == "clientCity") {
        if (data.length < 3) {
          console.log(data.length);
          this.profesionalCity = "true";
          $("#profesionalCity").html("<small>City required minimum 3 letters</small>");
        }
        if (data.length >= 3) {
          console.log(data.length);
          this.profesionalCity = "false";
          $("#profesionalCity").html("<small></small>");
        }
      }

      // done
      if (type == "pinCode") {
        if (data.length != 6) {
          console.log(data.length);
          this.pinCode = "true";
          $("#pinCode").html("<small>Pin Code 6 Digit Required</small>");
        }
        if (data.length == 6) {
          console.log(data.length);
          this.pinCode = "false";
          $("#pinCode").html("<small></small>");
        }
      }
    },

    clientModalValueNull() {
      this.loadState();
      this.form = {};
      this.panNo = "";
      this.exist = "false";
      this.panDisabled = "false";
    },
    findRecordeByPanId() {
            this.loading = true;
      this.$axios
        .get(`retailer/client?pan=${this.panNo}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.record = res.data.data.data[0];
          if (res.data.data.data[0]) {
            this.form = this.record;
            console.log(this.record);
            this.exist = "true";
            this.veryfied = "false";
            this.panDisabled = "true";
            this.edit = "true";
          } else {
            this.loading = true;
            this.$axios
              .post(
                "retailer/verifypan",
                { panNo: this.panNo },
                { headers: { Authorization: "Bearer " + localStorage.accessToken } }
              )
              .then((res) => {
                console.log(res.data.data);
                if (res.data.data.data.status == "VALID") {
                  this.form.fname = res.data.data.data.first_name;
                  this.form.lname = res.data.data.data.last_name;
                  // this.$swal.fire({
                  //   position: "top-end",
                  //   icon: "success",
                  //   title: "Pan Verrifiyed  Succesfully",
                  //   showConfirmButton: false,
                  //   timer: 1500,
                  // });

                  toast.success(" Pan Verified Succesfully", {
          autoClose: 1000,
        });

                  this.form.pan = this.panNo;
                  this.panDisabled = "true";
                  $("#panVerifiy").modal("hide");
                  $("#clientModal").modal("show");
                } else {
                  this.veryfied = "invalid";
                  this.exist = "false";
                }
              })
              .finally(() => (this.loading = false))
              .catch((error) => {
                // console.log(error.response);
                this.form.errors = error.response.data;
                // console.log(this.form.errors);

                //  alert(this.form.errors.error)
                this.$swal.fire({
                    position: "top-center",
                    icon: "error", 
                    title: "<small>"+error.response.data.error+"</small>",
                    showConfirmButton: true,
                    confirmButtonColor: "#00364f",
        
                  });

              });
            $("#clientModal").modal("show");
          }
        })
        .finally(() => (this.loading = false))
    },
    loadState() {
      this.$axios
        .get("retailer/state?all=all", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.states = res.data.data;
          //console.log(this.states)
        });
    },
    valueAssignClient(client) {
      this.form = client;
      this.client_id = client.id;
      this.edit = "true";
      console.log(this.form);
    },
    updateClient() {
      if (
        this.phoneClient == "false" &&
        this.phoneAltClient == "false" &&
        this.pinCode == "false" &&
        this.firstNameClient == "false" &&
        this.doBDate == "false"
      ) {
        this.loading = true;
        this.$axios
          .put(`retailer/client/${this.form.id}`, this.form, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            console.log(res.data);
          

            toast.success(" Client Updated successfully.", {
          autoClose: 1000,
        });

        this.form = {}
        this.form.errors = {}
        this.panDisabled = 'false'
            $("#clientModal").modal("hide");
            this.loadClients();
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            // console.log(error.response);
            this.form.errors = error.response.data;
            console.log(this.form.errors);
            //  alert(this.form.errors.error)
          })
      }
    },
    addNewClient() {
      if (
        this.phoneClient == "false" &&
        this.phoneAltClient == "false" &&
        this.pinCode == "false" &&
        this.firstNameClient == "false" &&
        this.doBDate == "false"
      ) {
        const formData = new FormData();
        const keys = Object.keys(this.form);
        for (let n = 0; n < keys.length; n += 1) {
          if (this.form[keys[n]] !== null) {
            formData.append(keys[n], this.form[keys[n]]);
          }
        }
        this.loading = true;
        this.$axios
          .post(`retailer/client`, formData, {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          })
          .then((res) => {
            console.log(res.data);
           
            toast.success(" Client Created Successfully.", {
          autoClose: 1000,
        });
this.panDisabled = 'false'
        this.form = {}
        this.form.errors = {}
            $("#clientModal").modal("hide");
            this.loadClients();
          })
          .finally(() => (this.loading = false))
          .catch((error) => {
            // console.log(error.response);
            this.form.errors = error.response.data;
            console.log(this.form.errors);
            //  alert(this.form.errors.error)
          })
          
      }
    },
    accountNoVall(e) {
  const inputValue = e.key;

  // Allowing only Backspace key
  if (inputValue === 'Backspace' || inputValue === 'Delete') {
    // Perform your specific action for the Backspace key
    console.log('Backspace key pressed!');
  } else {
    // Blocking other keys, including Delete, non-word characters, and alphabets
    if (/^\W$/.test(inputValue) || /[a-zA-Z]/.test(inputValue)) {
      this.error = true;
      e.preventDefault();
    } else {
      this.error = false;
    }
  }
},
    loadClients() {
      this.loading = true;
      this.$axios
        .get("retailer/client", {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.clients = res.data.data.data;
        })
        .finally(() => (this.loading = false));
    },
  },
  created() {
    this.loadState();
  },
};
</script>
